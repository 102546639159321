import React from 'react'
import { Zoom } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/story/21.jpg'
import sImg2 from '../../images/story/22.jpg'
import sImg3 from '../../images/story/23.jpg'

import shape1 from '../../images/story/shape.png'
import shape2 from '../../images/story/shape2.png'
import shape3 from '../../images/story/shape3.png'


import bshape1 from '../../images/story/flower-shape1.svg'
import bshape2 from '../../images/story/flower-shape2.svg'
import bshape3 from '../../images/story/flower-shape3.svg'
import bshape4 from '../../images/story/flower-shape4.svg'




const Storys = [
    {
        sImg: sImg1,
        shape: shape1,
        title: '¿Cómo se conocieron?',
        date: '',
        description: 'Antonio y Rocío, juntos desde el primer instante en que se conocieron, han construido una historia de amor genuina y llena de alegría que desean compartir con ustedes.',
    },
    {
        sImg: sImg2,
        shape: shape2,
        title: 'Se lo propuse, y ella dijo si',
        date: '',
        description: 'Desde el primer momento en que nuestras miradas se cruzaron, supimos que nuestro amor sería eterno.',
        order1: 'order-lg-2 order-1',
        order2: 'order-lg-1 order-2',
    },
    {
        sImg: sImg3,
        shape: shape3,
        title: 'Nuestro Día de Compromiso',
        date: '21 Julio 2024',
        description: 'Después de un tiempo de compartir risas, sueños y momentos especiales, han decidido dar un paso más en su viaje juntos y formalizar su compromiso con el amor que los une.',
    }

]


const StorySection2 = (props) => {
    return (
        <section className="wpo-story-section section-padding" id="story">
            <div className="container">
                <SectionTitle subTitle={'Nuestra historia'} MainTitle={'¿Cómo pasó?'} />
                <div className="wpo-story-wrap">
                    {
                        Storys.map((story, st) => (
                            <div className="wpo-story-item" key={st}>
                                <div className="wpo-story-img-wrap">
                                    <Zoom direction="up" duration="1000" triggerOnce="true">
                                        <div className="wpo-story-img">
                                            <img src={story.sImg} alt="" />
                                        </div>
                                    </Zoom>
                                    <div className="clip-shape">
                                        <svg viewBox="0 0 382 440" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M191 0L381.526 110V330L191 440L0.474411 330V110L191 0Z" />
                                        </svg>
                                    </div>
                                    <div className="wpo-img-shape">
                                        <img src={story.shape} alt="" />
                                    </div>
                                </div>
                                <div className="wpo-story-content">
                                    <div className="wpo-story-content-inner">
                                        <span>{story.date}</span>
                                        <h2>{story.title}</h2>
                                        <p>{story.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))

                    }
                </div>
            </div>
            <div className="flower-shape-1">
                <img src={bshape1} alt="" />
            </div>
            <div className="flower-shape-2">
                <img src={bshape2} alt="" />
            </div>
            <div className="flower-shape-3">
                <img src={bshape3} alt="" />
            </div>
            <div className="flower-shape-4">
                <img src={bshape4} alt="" />
            </div>
        </section>
    )
}

export default StorySection2;