import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Homepage from '../HomePage'
import HomePage2 from '../HomePage2/HomePage2';
import HomePage3 from '../HomePage3/HomePage3';
import HomePage4 from '../HomePage4/HomePage4';
import HomePage5 from '../HomePage5/HomePage5';
import HomePage6 from '../HomePage6/HomePage6';
import HomePage7 from '../HomePage7/HomePage7';
import InvitationPage1 from '../InvitationPage1/InvitationPage1';
import InvitationPage2 from '../InvitationPage2/InvitationPage2';
import HomePlanner1 from '../HomePlanner1/HomePlanner1';
import HomePlanner2 from '../HomePlanner2/HomePlanner2';
import HomePlanner3 from '../HomePlanner3/HomePlanner3';
import HomeShop1 from '../HomeShop1/HomeShop1';
import HomeShop2 from '../HomeShop2/HomeShop2';
import HomeShop3 from '../HomeShop3/HomeShop3';
import HomeShop4 from '../HomeShop4/HomeShop4';
import AboutPage from '../AboutPage/AboutPage';
import StoryPage1 from '../StoryPage1/StoryPage1';
import StoryPage2 from '../StoryPage2/StoryPage2';
import StoryPage3 from '../StoryPage3/StoryPage3';
import StoryPage4 from '../StoryPage4/StoryPage4';
import StoryPage5 from '../StoryPage5/StoryPage5';
import StoryPage6 from '../StoryPage6/StoryPage6';
import AccomodationPage from '../AccomodationPage/AccomodationPage';
import RsvpPage1 from '../RsvpPage1/RsvpPage1';
import RsvpPage2 from '../RsvpPage2/RsvpPage2';
import RsvpPage3 from '../RsvpPage3/RsvpPage3';
import RsvpPage4 from '../RsvpPage4/RsvpPage4';
import RsvpPage5 from '../RsvpPage5/RsvpPage5';
import RsvpPage6 from '../RsvpPage6/RsvpPage6';
import RsvpPage7 from '../RsvpPage7/RsvpPage7';
import GalleryPage from '../GalleryPage/GalleryPage';
import PlannerPage from '../PlannerPage/PlannerPage';
import TeamSinglePage from '../TeamSinglePage/TeamSinglePage';
import BrideGroomsPage from '../BrideGroomsPage/BrideGroomsPage';
import ServicePage from '../ServicePage/ServicePage';
import ServicePageS2 from '../ServicePageS2/ServicePageS2';
import ServicePageS3 from '../ServicePageS3/ServicePageS3';
import SeviceSinglePage from '../SeviceSinglePage';
import PricingPage from '../PricingPage/PricingPage';
import ProductSinglePage from '../ProductSinglePage';
import PortfolioGridPage from '../PortfolioGridPage/PortfolioGridPage';
import PortfolioMasonaryPage from '../PortfolioMasonaryPage/PortfolioMasonaryPage';
import PortfolioMasonaryPageS2 from '../PortfolioMasonaryPageS2/PortfolioMasonaryPageS2';
import PortfolioMasonaryPageS3 from '../PortfolioMasonaryPageS3/PortfolioMasonaryPageS3';
import ProjectSinglePage from '../ProjectSinglePage';
import ShopPage from '../ShopPage';
import CartPage from '../CartPage/CartPage';
import WishlistPage from '../WishlistPage/WishlistPage';
import CheckoutPage from '../CheckoutPage';
import OrderRecived from '../OrderRecived/OrderRecived';
import BlogDetails from '../BlogDetails/BlogDetails';
import BlogPage from '../BlogPage/BlogPage';
import BlogPageLeft from '../BlogPageLeft/BlogPageLeft';
import BlogDetailsFull from '../BlogDetailsFull/BlogDetailsFull';
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide/BlogDetailsLeftSiide';
import BlogPageFullwidth from '../BlogPageFullwidth/BlogPageFullwidth';
import ContactPage from '../ContactPage/ContactPage';
import ComingSoonPage from '../ComingSoonPage/ComingSoonPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import LoginPage from '../LoginPage'
import SignUpPage from '../SignUpPage'
import ForgotPassword from '../ForgotPassword'

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='home' element={<Homepage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
